import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import { Html5QrcodeScanner } from "html5-qrcode";
import { Html5QrcodeTranslate } from "../utils/qrTranslations";

const qrcodeRegionId = "html5qr-code-full-region";

const createConfig = (props) => {
  let config = {};
  if (props.fps) config.fps = props.fps;
  if (props.qrbox) config.qrbox = props.qrbox;
  if (props.aspectRatio) config.aspectRatio = props.aspectRatio;
  if (props.disableFlip !== undefined) config.disableFlip = props.disableFlip;
  return config;
};

const Html5QrcodePlugin = forwardRef((props, ref) => {
  const [capturedImage, setCapturedImage] = useState(null);
  const html5QrcodeScannerRef = useRef(null);
  const translatorRef = useRef(null);

  useEffect(() => {
    const config = createConfig(props);
    const verbose = props.verbose === true;

    if (!props.qrCodeSuccessCallback) {
      throw new Error("qrCodeSuccessCallback is required callback.");
    }

    const html5QrcodeScanner = new Html5QrcodeScanner(
      qrcodeRegionId,
      config,
      verbose
    );

    html5QrcodeScanner.render(
      (decodedText) => {
        props.qrCodeSuccessCallback(decodedText);
        html5QrcodeScanner.pause();
        captureCurrentFrame().then((imageData) => {
          setCapturedImage(imageData);
        });
      },
      props.qrCodeErrorCallback
    );

    html5QrcodeScannerRef.current = html5QrcodeScanner;

    // Inicializar el traductor después de renderizar el escáner
    setTimeout(() => {
      translatorRef.current = new Html5QrcodeTranslate(`#${qrcodeRegionId}`);
    }, 100);

    return () => {
      if (translatorRef.current) {
        translatorRef.current.disconnect();
      }
      if (html5QrcodeScannerRef.current) {
        html5QrcodeScannerRef.current.clear().catch((error) => {
          console.error("Failed to clear Html5QrcodeScanner: ", error);
        });
      }
    };
  }, []);

  const captureCurrentFrame = async () => {
    const videoElement = document.querySelector(`#${qrcodeRegionId} video`);
    if (!videoElement) return null;

    const canvas = document.createElement("canvas");
    canvas.width = videoElement.videoWidth;
    canvas.height = videoElement.videoHeight;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

    return canvas.toDataURL("image/png");
  };

  const handleResume = async () => {
    if (!html5QrcodeScannerRef.current) {
      console.error("Scanner not initialized.");
      return;
    }

    setCapturedImage(null);

    try {
      await html5QrcodeScannerRef.current.resume();
      console.log("Scanner resumed successfully.");
    } catch (error) {
      console.error("Failed to resume the scanner: ", error);
    }
  };

  useImperativeHandle(ref, () => ({
    resumeScanner: handleResume,
  }));

  return (
    <div>
      <div
        id={qrcodeRegionId}
        style={{
          display: capturedImage ? "none" : "block",
        }}
      />
      {capturedImage && (
        <div style={{ position: "relative", width: "100%", height: "100%" }}>
          <img
            src={capturedImage}
            alt="Captured QR Code"
            style={{
              width: "100%",
              height: "auto",
              maxWidth: "500px",
              maxHeight: "500px",
            }}
          />
          <button
            id="scan-result-close"
            className="scanapp-button"
            onClick={handleResume}
            style={{
              display: "block",
              margin: "10px auto",
              padding: "10px 20px",
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              fontSize: "16px",
            }}
          >
            Escanear otro código
          </button>
        </div>
      )}
    </div>
  );
});

export default Html5QrcodePlugin;
