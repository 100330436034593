// src/utils/theme.js
export const THEME_DEFAULTS = {
    DEFAULT_THEME: false, // false = light mode, true = dark mode
    STORAGE_KEY: 'darkMode'
  };
  
  export function getInitialTheme() {
    try {
      // 1. Primero intenta obtener la preferencia guardada
      const saved = localStorage.getItem(THEME_DEFAULTS.STORAGE_KEY);
      if (saved !== null) {
        return JSON.parse(saved);
      }
  
      // 2. Si no hay preferencia guardada, intenta obtener la preferencia del sistema
      if (window.matchMedia) {
        const systemPreference = window.matchMedia('(prefers-color-scheme: dark)').matches;
        return systemPreference;
      }
  
      // 3. Si no hay preferencia del sistema, usa el valor por defecto
      return THEME_DEFAULTS.DEFAULT_THEME;
    } catch (error) {
      console.warn('Error getting theme preference:', error);
      return THEME_DEFAULTS.DEFAULT_THEME;
    }
  }
  