import React, { useState, useEffect } from 'react';
import VotingForm from '../components/VotingForm';
import { Logo } from '../components/Logo';
import { useTheme } from '../context/ThemeContext';

export default function Home() {
  const [showLogo, setShowLogo] = useState(true);
  const [showContent, setShowContent] = useState(false);
  const { darkMode } = useTheme();

  useEffect(() => {
    const logoTimer = setTimeout(() => {
      setShowLogo(false);
      setShowContent(true);
    }, 6000);

    return () => clearTimeout(logoTimer);
  }, []);

  if (showLogo) {
    return (
      <div className={`min-h-screen ${darkMode ? 'bg-gray-900' : 'bg-white'} flex items-center justify-center`}>
        <div className="w-[600px] h-auto">
          <Logo />
        </div>
      </div>
    );
  }

  return (
    <div 
      className={`min-h-screen bg-gray-50 dark:bg-gray-900 py-12 transition-opacity duration-1000 ${
        showContent ? 'opacity-100' : 'opacity-0'
      }`}
    >
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <VotingForm />
      </div>
    </div>
  );
}
