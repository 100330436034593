export const translates = [
    { en: "QR code parse error, error =", es: "Error al analizar el código QR, error =" },
    { en: "Error getting userMedia, error =", es: "Error al obtener userMedia, error =" },
    { en: "The device doesn't support navigator.mediaDevices , only supported cameraIdOrConfig in this case is deviceId parameter (string).", es: "El dispositivo no admite navigator.mediaDevices, en este caso sólo se admite cameraIdOrConfig como parámetro deviceId (cadena)." },
    { en: "Camera streaming not supported by the browser.", es: "El navegador no admite la transmisión de la cámara." },
    { en: "Unable to query supported devices, unknown error.", es: "No se puede consultar los dispositivos compatibles, error desconocido." },
    { en: "Camera access is only supported in secure context like https or localhost.", es: "El acceso a la cámara sólo es compatible en un contexto seguro como https o localhost." },
    { en: "Scanner paused", es: "Escáner en pausa" },
    { en: "Scanning", es: "Escaneando" },
    { en: "Idle", es: "Inactivo" },
    { en: "Error", es: "Error" },
    { en: "Permission", es: "Permiso" },
    { en: "No Cameras", es: "Sin cámaras" },
    { en: "Last Match:", es: "Última coincidencia:" },
    { en: "Code Scanner", es: "Escáner de código" },
    { en: "Request Camera Permissions", es: "Solicitar permisos de cámara" },
    { en: "Requesting camera permissions...", es: "Solicitando permisos de cámara..." },
    { en: "No camera found", es: "No se encontró ninguna cámara" },
    { en: "Stop Scanning", es: "Detener escáner" },
    { en: "Start Scanning", es: "Iniciar escáner" },
    { en: "Switch On Torch", es: "Encender linterna" },
    { en: "Switch Off Torch", es: "Apagar linterna" },
    { en: "Failed to turn on torch", es: "Error al encender la linterna" },
    { en: "Failed to turn off torch", es: "Error al apagar la linterna" },
    { en: "Launching Camera...", es: "Iniciando cámara..." },
    { en: "Scan an Image File", es: "Escanear un archivo de imagen" },
    { en: "Scan using camera directly", es: "Escanear usando la cámara directamente" },
    { en: "Select Camera", es: "Seleccionar cámara" },
    { en: "Choose Image", es: "Elegir imagen" },
    { en: "Choose Another", es: "Elegir otra" },
    { en: "Choose Image - No image choosen", es: "Elegir imagen - Ninguna imagen seleccionada" },
    { en: "No image choosen", es: "Ninguna imagen seleccionada" },
    { en: "Anonymous Camera", es: "Cámara anónima" },
    { en: "Or drop an image to scan", es: "O arrastra una imagen para escanear" },
    { en: "Or drop an image to scan (other files not supported)", es: "O arrastra una imagen para escanear (otros archivos no soportados)" },
    { en: "zoom", es: "zoom" },
    { en: "Loading image...", es: "Cargando imagen..." },
    { en: "Camera based scan", es: "Escaneo basado en cámara" },
    { en: "Fule based scan", es: "Escaneo basado en archivo" },
    { en: "Powered by ", es: "Desarrollado por " },
    { en: "Report issues", es: "Informar de problemas" },
    { en: "NotAllowedError: Permission denied", es: "Permiso denegado para acceder a la cámara" }
];

export class Html5QrcodeTranslate {
    #observer = null;

    constructor(elementById) {
        this.#observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type === 'childList') {
                    mutation.addedNodes.forEach((nodo) => {
                        this.#textNodeTranslate(nodo);
                    });
                }
            });
        });

        const config = { childList: true, subtree: true };
        this.#observer.observe(document.querySelector(elementById), config);

        this.#textNodeTranslate(document.querySelector(elementById));

        return this.#observer;
    }

    disconnect() {
        this.#observer !== null && this.#observer.disconnect();
    }

    #translate(texto) {
        const translate = translates.find(t => t.en === texto);
        return translate ? translate.es : texto;
    }

    #textNodeTranslate(nodo) {
        if (nodo.nodeType === Node.TEXT_NODE) {
            nodo.textContent = this.#translate(nodo.textContent.trim());
        } else {
            for (let i = 0; i < nodo.childNodes.length; i++) {
                this.#textNodeTranslate(nodo.childNodes[i]);
            }
        }
    }
}