import React, { useState, useEffect } from 'react';
import { superadminApi } from '../../services/api';
import { TrashIcon } from '@heroicons/react/24/outline';

function ContestStatus() {
  const [status, setStatus] = useState(null);
  const [days, setDays] = useState([]);
  const [selectedDay, setSelectedDay] = useState('');
  const [message, setMessage] = useState({ type: '', text: '' });
  const [isLoading, setIsLoading] = useState(true);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  useEffect(() => {
    const initialize = async () => {
      try {
        const [statusResponse, daysResponse] = await Promise.all([
          superadminApi.contestStatus.get().catch(error => {
            if (error.response?.status === 404) {
              return { data: null };
            }
            throw error;
          }),
          superadminApi.classificationDays.getAll()
        ]);
        
        setDays(daysResponse.data);
        
        if (statusResponse.data) {
          setStatus(statusResponse.data);
          setSelectedDay(statusResponse.data.classification_day_id.toString());
        }
      } catch (error) {
        setMessage({
          type: 'error',
          text: 'Error al cargar los datos'
        });
      } finally {
        setIsLoading(false);
      }
    };

    initialize();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await superadminApi.contestStatus.update(selectedDay);
      const selectedDayData = days.find(d => d.id === parseInt(selectedDay));
      
      setStatus({
        ...response.data.contestStatus,
        ClassificationDay: selectedDayData
      });
      
      setMessage({ 
        type: 'success', 
        text: 'Estado del concurso actualizado exitosamente' 
      });

      setTimeout(() => {
        setMessage({ type: '', text: '' });
      }, 3000);
    } catch (error) {
      setMessage({
        type: 'error',
        text: error.response?.data?.message || 'Error al actualizar estado'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    if (!status) return;
    
    setIsLoading(true);
    try {
      await superadminApi.contestStatus.delete(status.id);
      setStatus(null);
      setSelectedDay('');
      setMessage({
        type: 'success',
        text: 'Estado del concurso eliminado exitosamente'
      });
      setShowConfirmDelete(false);
    } catch (error) {
      setMessage({
        type: 'error',
        text: error.response?.data?.message || 'Error al eliminar el estado'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      return date.toLocaleDateString('es-ES', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    } catch (error) {
      return 'Fecha no disponible';
    }
  };

  const formatDateTime = (dateString) => {
    try {
      const date = new Date(dateString);
      return date.toLocaleDateString('es-ES', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    } catch (error) {
      return 'Fecha no disponible';
    }
  };

  if (isLoading) {
    return (
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
        <div className="flex items-center justify-center h-64">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-500"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
      <div className="grid gap-8 md:grid-cols-2">
        {/* Estado Actual */}
        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <h3 className="text-lg font-medium text-gray-900 dark:text-white">
              Estado Actual
            </h3>
            {status && (
              <button
                onClick={() => setShowConfirmDelete(true)}
                className="text-red-600 hover:text-red-800 dark:text-red-400 dark:hover:text-red-300"
                title="Eliminar estado actual"
              >
                <TrashIcon className="h-5 w-5" />
              </button>
            )}
          </div>
          <div className="bg-gray-50 dark:bg-gray-700 rounded-lg p-6 space-y-3">
            <div className="flex items-start md:items-center space-x-3">
              <div className="flex-shrink-0 mt-1 md:mt-0">
                <div className={`w-4 h-4 rounded-full ${status ? 'bg-green-500' : 'bg-yellow-500'}`}></div>
              </div>
              <div className="flex-1 min-w-0">
                <p className="text-gray-700 dark:text-gray-300 break-words">
                  {status?.ClassificationDay ? (
                    <>
                      <span className="font-medium block md:inline">Día actual:</span>{' '}
                      <span className="block md:inline mt-1 md:mt-0">
                        {formatDate(status.ClassificationDay.date)} - {status.ClassificationDay.description}
                      </span>
                    </>
                  ) : (
                    'No hay ningún día de concurso establecido'
                  )}
                </p>
              </div>
            </div>
            {status?.updatedAt && (
              <p className="text-sm text-gray-600 dark:text-gray-400 mt-2 break-words">
                <span className="font-medium">Última actualización:</span>{' '}
                {formatDateTime(status.updatedAt)}
              </p>
            )}
          </div>
        </div>

        {/* Formulario de Actualización */}
        <div className="space-y-4">
          <h3 className="text-lg font-medium text-gray-900 dark:text-white">
            Actualizar Estado
          </h3>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Seleccionar Día de Clasificación
              </label>
              <select
                value={selectedDay}
                onChange={(e) => setSelectedDay(e.target.value)}
                className="form-select"
                required
              >
                <option value="">Seleccione un día</option>
                {days.map((day) => (
                  <option key={day.id} value={day.id}>
                    {formatDate(day.date)} - {day.description}
                  </option>
                ))}
              </select>
            </div>

            {message.text && (
              <div className={`p-4 rounded-lg border ${
                message.type === 'success' 
                  ? 'bg-green-50 text-green-800 border-green-200 dark:bg-green-900/30 dark:text-green-100 dark:border-green-800' 
                  : 'bg-red-50 text-red-800 border-red-200 dark:bg-red-900/30 dark:text-red-100 dark:border-red-800'
              }`}>
                {message.text}
              </div>
            )}

            <button
              type="submit"
              disabled={isLoading}
              className="w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:bg-indigo-500 dark:hover:bg-indigo-600 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-200"
            >
              {isLoading ? (
                <>
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Actualizando...
                </>
              ) : (
                'Actualizar Estado'
              )}
            </button>
          </form>
        </div>
      </div>

      {/* Modal de confirmación de eliminación */}
      {showConfirmDelete && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white dark:bg-gray-800 rounded-lg p-6 max-w-sm w-full">
            <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
              Confirmar Eliminación
            </h3>
            <p className="text-gray-500 dark:text-gray-400 mb-6">
              ¿Está seguro que desea eliminar el estado actual del concurso?
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowConfirmDelete(false)}
                className="px-4 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg"
              >
                Cancelar
              </button>
              <button
                onClick={handleDelete}
                className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
              >
                Eliminar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ContestStatus;