import React, { useState, useEffect } from "react";
import { superadminApi } from "../../services/api";
import {
  PlusIcon,
  TrashIcon,
  PencilIcon,
  XMarkIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";

function ContestantManagement() {
  const [contestants, setContestants] = useState([]);
  const [types, setTypes] = useState([]);
  const [days, setDays] = useState([]);
  const [selectedDay, setSelectedDay] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [contestantsList, setContestantsList] = useState([
    {
      contestant_name: "",
      contestants_type_id: "",
      classification_day_id: "",
      order: "",
    },
  ]);
  const [message, setMessage] = useState({ type: "", text: "" });
  const [isEditing, setIsEditing] = useState(false);
  const [editingId, setEditingId] = useState(null);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [formDataLoaded, setFormDataLoaded] = useState(false);

  useEffect(() => {
    loadContestants();
    loadDays();
  }, []);

  useEffect(() => {
    if (showForm && !formDataLoaded) {
      loadFormData();
    }
  }, [showForm]);

  useEffect(() => {
    if (!isEditing) {
      const nextOrder = getNextOrder();
      const updatedList = contestantsList.map((contestant, index) => ({
        ...contestant,
        order: contestant.order || nextOrder + index,
      }));
      setContestantsList(updatedList);
    }
  }, [contestantsList.length]);

  const loadContestants = async () => {
    try {
      const response = await superadminApi.contestants.getAll();
      setContestants(response.data.sort((a, b) => a.order - b.order));
    } catch (error) {
      setMessage({
        type: "error",
        text: "Error al cargar los concursantes",
      });
    }
  };

  const loadDays = async () => {
    try {
      const response = await superadminApi.classificationDays.getAll();
      setDays(response.data);
      if (response.data.length > 0) {
        setSelectedDay(response.data[0].id.toString());
      }
    } catch (error) {
      setMessage({
        type: "error",
        text: "Error al cargar los días",
      });
    }
  };

  const loadFormData = async () => {
    try {
      const typesRes = await superadminApi.contestantTypes.getAll();
      setTypes(typesRes.data);
      setFormDataLoaded(true);
    } catch (error) {
      setMessage({
        type: "error",
        text: "Error al cargar los datos del formulario",
      });
    }
  };

  const getNextOrder = () => {
    if (contestants.length === 0) return 1;
    return Math.max(...contestants.map((c) => c.order)) + 1;
  };

  const handleContestantChange = (index, field, value) => {
    const updatedContestants = [...contestantsList];
    updatedContestants[index] = {
      ...updatedContestants[index],
      [field]: value,
    };
    setContestantsList(updatedContestants);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isEditing) {
        const contestant = {
          id: editingId,
          ...contestantsList[0],
        };
        await superadminApi.contestants.update(contestant);
        setMessage({
          type: "success",
          text: "Concursante actualizado exitosamente",
        });
      } else {
        await superadminApi.contestants.create(contestantsList);
        setMessage({
          type: "success",
          text: "Concursantes creados exitosamente",
        });
      }

      await loadContestants();
      resetForm();
    } catch (error) {
      setMessage({
        type: "error",
        text:
          error.response?.data?.message || "Error al guardar los concursantes",
      });
    }
  };

  const resetForm = () => {
    setContestantsList([
      {
        contestant_name: "",
        contestants_type_id: "",
        classification_day_id: "",
        order: getNextOrder(),
      },
    ]);
    setIsEditing(false);
    setEditingId(null);
    setShowForm(false);
  };

  const handleEdit = (contestant) => {
    setIsEditing(true);
    setEditingId(contestant.id);
    setContestantsList([
      {
        contestant_name: contestant.contestant_name,
        contestants_type_id: contestant.contestants_type_id,
        classification_day_id: contestant.classification_day_id,
        order: contestant.order,
      },
    ]);
    setShowForm(true);
  };

  const confirmDelete = (id) => {
    setDeleteId(id);
    setShowConfirmDelete(true);
  };

  const handleDelete = async (id) => {
    try {
      await superadminApi.contestants.delete(id);
      setMessage({
        type: "success",
        text: "Concursante eliminado exitosamente",
      });
      await loadContestants();
    } catch (error) {
      setMessage({
        type: "error",
        text:
          error.response?.data?.message || "Error al eliminar el concursante",
      });
    } finally {
      setShowConfirmDelete(false);
      setDeleteId(null);
    }
  };

  const addNewContestant = () => {
    setContestantsList([
      ...contestantsList,
      {
        contestant_name: "",
        contestants_type_id: "",
        classification_day_id: "",
        order: "",
      },
    ]);
  };

  const handleRemoveContestant = (index) => {
    const updatedContestants = contestantsList.filter((_, i) => i !== index);
    setContestantsList(updatedContestants);
  };

  const filteredContestants = contestants
    .filter(
      (contestant) =>
        contestant.classification_day_id.toString() === selectedDay
    )
    .filter((contestant) =>
      contestant.contestant_name
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-6">
      <div className="flex justify-between items-center mb-6">
        {!showForm && (
          <button
            onClick={() => setShowForm(true)}
            className="flex items-center px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors duration-200"
          >
            <PlusIcon className="h-5 w-5 mr-2" />
            Añadir Concursante
          </button>
        )}
      </div>

      {showForm && (
        <div className="mb-8 bg-gray-50 dark:bg-gray-700 rounded-lg p-6 relative">
          <button
            onClick={resetForm}
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
          >
            <XMarkIcon className="h-6 w-6" />
          </button>

          <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
            {isEditing ? "Editar Concursante" : "Nuevo Concursante"}
          </h3>

          <form onSubmit={handleSubmit} className="space-y-6">
            {contestantsList.map((contestant, index) => (
              <div
                key={index}
                className="bg-white dark:bg-gray-800 rounded-lg p-4 shadow-sm space-y-4"
              >
                <div className="flex justify-between items-center">
                  <div className="flex items-center space-x-3">
                    <input
                      type="number"
                      value={contestant.order}
                      onChange={(e) =>
                        handleContestantChange(
                          index,
                          "order",
                          parseInt(e.target.value) || ""
                        )
                      }
                      className="w-20 text-sm font-medium text-gray-700 dark:text-gray-300 form-input"
                      min="1"
                      placeholder="Orden"
                    />
                    {!isEditing && contestantsList.length > 1 && (
                      <button
                        type="button"
                        onClick={() => handleRemoveContestant(index)}
                        className="text-red-600 hover:text-red-800 dark:text-red-400 dark:hover:text-red-300"
                      >
                        <TrashIcon className="h-5 w-5" />
                      </button>
                    )}
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                      Nombre
                    </label>
                    <input
                      type="text"
                      value={contestant.contestant_name}
                      onChange={(e) =>
                        handleContestantChange(
                          index,
                          "contestant_name",
                          e.target.value
                        )
                      }
                      className="form-input"
                      required
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                      Tipo
                    </label>
                    <select
                      value={contestant.contestants_type_id}
                      onChange={(e) =>
                        handleContestantChange(
                          index,
                          "contestants_type_id",
                          e.target.value
                        )
                      }
                      className="form-select"
                      required
                    >
                      <option value="">Seleccione un tipo</option>
                      {types.map((type) => (
                        <option key={type.id} value={type.id}>
                          {type.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                      Día de Clasificación
                    </label>
                    <select
                      value={contestant.classification_day_id}
                      onChange={(e) =>
                        handleContestantChange(
                          index,
                          "classification_day_id",
                          e.target.value
                        )
                      }
                      className="form-select"
                      required
                    >
                      <option value="">Seleccione un día</option>
                      {days.map((day) => (
                        <option key={day.id} value={day.id}>
                          {new Date(day.date).toLocaleDateString()} -{" "}
                          {day.description}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            ))}

            {!isEditing && (
              <button
                type="button"
                onClick={addNewContestant}
                className="flex items-center justify-center w-full py-2 px-4 border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-lg text-gray-600 dark:text-gray-400 hover:border-gray-500 dark:hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <PlusIcon className="h-5 w-5 mr-2" />
                Añadir otro concursante
              </button>
            )}

            {message.text && (
              <div
                className={`p-4 rounded-lg ${
                  message.type === "success"
                    ? "bg-green-50 text-green-800 dark:bg-green-900/30 dark:text-green-100"
                    : "bg-red-50 text-red-800 dark:bg-red-900/30 dark:text-red-100"
                }`}
              >
                {message.text}
              </div>
            )}

            <div className="flex gap-4">
              <button
                type="submit"
                className="flex-1 py-2 px-4 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:bg-indigo-500 dark:hover:bg-indigo-600"
              >
                {isEditing ? "Actualizar Concursante" : "Guardar Concursantes"}
              </button>

              <button
                type="button"
                onClick={resetForm}
                className="flex-1 py-2 px-4 border border-gray-300 rounded-lg shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 dark:border-gray-600"
              >
                Cancelar
              </button>
            </div>
          </form>
        </div>
      )}

      <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 mb-6">
        <div className="w-full md:w-auto">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
            Filtrar por día
          </label>
          <select
            value={selectedDay}
            onChange={(e) => setSelectedDay(e.target.value)}
            className="form-select w-full md:w-auto"
          >
            {days.map((day) => (
              <option key={day.id} value={day.id}>
                {new Date(day.date).toLocaleDateString()} - {day.description}
              </option>
            ))}
          </select>
        </div>

        <div className="w-full md:w-64 relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="text"
            placeholder="Buscar concursante..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="form-input pl-10 w-full"
          />
        </div>
      </div>

      <div className="mt-8">
        <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
          Lista de Concursantes -{" "}
          {days.find((d) => d.id.toString() === selectedDay)?.description}
        </h3>
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                <thead className="bg-gray-50 dark:bg-gray-700">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:text-white sm:pl-6"
                    >
                      Orden
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white"
                    >
                      Nombre
                    </th>
                    <th
                      scope="col"
                      className="hidden md:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white"
                    >
                      Tipo
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Acciones</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:divide-gray-700 bg-white dark:bg-gray-800">
                  {filteredContestants.map((contestant) => (
                    <tr key={contestant.id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:text-white sm:pl-6">
                        {contestant.order}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:text-white">
                        {contestant.contestant_name}
                      </td>
                      <td className="hidden md:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-900 dark:text-white">
                        {
                          types.find(
                            (t) => t.id === contestant.contestants_type_id
                          )?.name
                        }
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <div className="flex justify-end gap-2">
                          <button
                            onClick={() => handleEdit(contestant)}
                            className="text-indigo-600 hover:text-indigo-900 dark:text-indigo-400 dark:hover:text-indigo-300"
                          >
                            <PencilIcon className="h-5 w-5" />
                            <span className="sr-only">Editar</span>
                          </button>
                          <button
                            onClick={() => confirmDelete(contestant.id)}
                            className="text-red-600 hover:text-red-900 dark:text-red-400 dark:hover:text-red-300"
                          >
                            <TrashIcon className="h-5 w-5" />
                            <span className="sr-only">Eliminar</span>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {showConfirmDelete && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white dark:bg-gray-800 rounded-lg p-6 max-w-sm w-full">
            <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">
              Confirmar Eliminación
            </h3>
            <p className="text-gray-500 dark:text-gray-400 mb-6">
              ¿Está seguro que desea eliminar este concursante?
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowConfirmDelete(false)}
                className="px-4 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg"
              >
                Cancelar
              </button>
              <button
                onClick={() => handleDelete(deleteId)}
                className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
              >
                Eliminar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ContestantManagement;
