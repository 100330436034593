import React from 'react';

export function Logo({ className = '' }) {
  return (
    <div className="logo-container fixed inset-0 flex flex-col items-center justify-center">
      <svg
        className={`w-[600px] h-auto filter drop-shadow-[0_0_8px_rgba(11,204,195,0.3)] ${className}`}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 1440 809.999993"
        preserveAspectRatio="xMidYMid meet"
      >
        <defs>
          <clipPath id="43fe28f9bf">
            <path d="M 630 93 L 695 93 L 695 159 L 630 159 Z M 630 93 " />
          </clipPath>
          <clipPath id="937013a8fb">
            <path d="M 682.523438 144.589844 C 668.925781 159.5 648.757812 163.1875 637.433594 152.878906 C 626.113281 142.566406 627.980469 122.097656 641.578125 107.1875 C 655.175781 92.277344 675.347656 88.585938 686.667969 98.898438 C 697.992188 109.257812 696.121094 129.730469 682.523438 144.589844 Z M 682.523438 144.589844 " />
          </clipPath>
          <linearGradient
            x1="24.064699"
            gradientTransform="matrix(5.05486, 0, 0, 5.054335, 517.229682, 11.81172)"
            y1="31.278831"
            x2="33.232874"
            gradientUnits="userSpaceOnUse"
            y2="13.859885"
            id="60fb04183a"
          >
            <stop stopOpacity="1" stopColor="rgb(89.7995%, 5.499268%, 47.799683%)" offset="0" />
            <stop stopOpacity="1" stopColor="rgb(92.74292%, 31.138611%, 61.949158%)" offset="1" />
          </linearGradient>
          <clipPath id="f6a15898e0">
            <path d="M 543 11.8125 L 884 11.8125 L 884 656 L 543 656 Z M 543 11.8125 " />
          </clipPath>
          <clipPath id="0492e1310b">
            <path d="M 883.910156 640.519531 C 882.394531 652.449219 879.613281 654.519531 879.105469 655.582031 C 879.105469 655.632812 879.054688 655.683594 879.054688 655.734375 C 861.3125 651.742188 841.296875 643.90625 827.042969 635.011719 C 771.488281 600.488281 721.496094 553.585938 678.125 501.019531 C 693.847656 505.621094 709.566406 510.117188 725.035156 514.921875 C 731.960938 517.09375 739.085938 519.316406 746.214844 521.691406 C 757.539062 534.378906 769.214844 546.710938 781.347656 558.640625 C 811.167969 587.957031 846.199219 620.203125 883.910156 640.519531 Z M 654.214844 397.203125 C 610.644531 325.128906 573.492188 258.515625 595.125 173.046875 C 616.050781 90.101562 688.941406 21.871094 775.484375 11.8125 C 675.394531 13.632812 601.191406 61.140625 566.566406 125.886719 C 540.988281 173.703125 539.167969 218.585938 548.824219 270.1875 C 553.929688 293.792969 561.207031 316.941406 571.621094 338.371094 C 596.539062 386.589844 626.871094 435.414062 662 480.753906 C 684.039062 489.195312 707.292969 495.511719 728.925781 501.628906 C 701.378906 468.875 676.40625 433.898438 654.214844 397.203125 Z M 654.214844 397.203125 " />
          </clipPath>
          <linearGradient
            x1="23.023608"
            gradientTransform="matrix(5.05486, 0, 0, 5.054335, 517.229682, 11.81172)"
            y1="-3.616239"
            x2="49.2543"
            gradientUnits="userSpaceOnUse"
            y2="131.90698"
            id="681d653b62"
          >
            <stop stopOpacity="1" stopColor="rgb(3.898621%, 47.799683%, 52.198792%)" offset="0" />
            <stop stopOpacity="1" stopColor="rgb(1.599121%, 78.399658%, 80.799866%)" offset="1" />
          </linearGradient>
          <clipPath id="4cefb1fee7">
            <path d="M 517 132 L 935 132 L 935 658.3125 L 517 658.3125 Z M 517 132 " />
          </clipPath>
          <clipPath id="b005467ea3">
            <path d="M 926.269531 654.117188 C 914.691406 660.789062 893.714844 658.210938 879.054688 655.734375 C 879.054688 655.683594 879.105469 655.632812 879.105469 655.582031 C 879.664062 654.519531 882.394531 652.5 883.910156 640.519531 C 884.0625 639.660156 884.160156 638.699219 884.261719 637.640625 C 885.980469 620.050781 871.777344 600.289062 861.011719 587.398438 C 832.652344 553.382812 788.269531 535.441406 746.265625 521.640625 C 739.136719 519.316406 732.0625 517.09375 725.085938 514.871094 C 709.667969 510.066406 693.898438 505.621094 678.175781 500.96875 C 644.765625 491.214844 611.402344 480.703125 581.074219 464.277344 C 524.003906 433.292969 505.554688 375.421875 524.304688 314.3125 C 576.066406 145.699219 794.4375 127 878.855469 133.417969 C 796.359375 144.941406 723.570312 176.28125 652.597656 227.53125 C 595.628906 268.773438 573.84375 322.097656 568.132812 348.582031 C 563.429688 379.3125 568.992188 410.042969 591.183594 435.566406 C 609.429688 456.644531 634.804688 470.390625 662 480.804688 C 684.089844 489.292969 707.292969 495.5625 728.875 501.679688 C 734.386719 503.246094 739.796875 504.761719 745.050781 506.378906 C 796.105469 521.742188 852.671875 537.007812 893.917969 572.640625 C 906.859375 583.8125 954.222656 638.09375 926.269531 654.117188 Z M 926.269531 654.117188 " />
          </clipPath>
          <linearGradient
            x1="41.319"
            gradientTransform="matrix(5.05486, 0, 0, 5.054335, 517.229682, 11.81172)"
            y1="127.910004"
            x2="41.319"
            gradientUnits="userSpaceOnUse"
            y2="22.789994"
            id="453db39e29"
          >
            <stop stopOpacity="1" stopColor="rgb(80.000305%, 0%, 14.498901%)" offset="0" />
            <stop stopOpacity="1" stopColor="rgb(99.943542%, 31.710815%, 0.0396729%)" offset="1" />
          </linearGradient>
        </defs>
        {/* Outline paths that will be drawn */}
        <g>
          {/* Blue paths */}
          <g className="logo-path-blue">
            <path
              d="M 883.910156 640.519531 C 882.394531 652.449219 879.613281 654.519531 879.105469 655.582031 C 879.105469 655.632812 879.054688 655.683594 879.054688 655.734375 C 861.3125 651.742188 841.296875 643.90625 827.042969 635.011719 C 771.488281 600.488281 721.496094 553.585938 678.125 501.019531 C 693.847656 505.621094 709.566406 510.117188 725.035156 514.921875 C 731.960938 517.09375 739.085938 519.316406 746.214844 521.691406 C 757.539062 534.378906 769.214844 546.710938 781.347656 558.640625 C 811.167969 587.957031 846.199219 620.203125 883.910156 640.519531 Z"
              fill="none"
            />
            <path
              d="M 654.214844 397.203125 C 610.644531 325.128906 573.492188 258.515625 595.125 173.046875 C 616.050781 90.101562 688.941406 21.871094 775.484375 11.8125 C 675.394531 13.632812 601.191406 61.140625 566.566406 125.886719 C 540.988281 173.703125 539.167969 218.585938 548.824219 270.1875 C 553.929688 293.792969 561.207031 316.941406 571.621094 338.371094 C 596.539062 386.589844 626.871094 435.414062 662 480.753906 C 684.039062 489.195312 707.292969 495.511719 728.925781 501.628906 C 701.378906 468.875 676.40625 433.898438 654.214844 397.203125 Z"
              fill="none"
            />
          </g>
          
          {/* Red paths */}
          <g className="logo-path-red">
            <path
              d="M 682.523438 144.589844 C 668.925781 159.5 648.757812 163.1875 637.433594 152.878906 C 626.113281 142.566406 627.980469 122.097656 641.578125 107.1875 C 655.175781 92.277344 675.347656 88.585938 686.667969 98.898438 C 697.992188 109.257812 696.121094 129.730469 682.523438 144.589844 Z"
              fill="none"
            />
          </g>
        </g>

        {/* Gradient-filled paths that will fade in */}
        <g className="logo-gradient">
          <g clipPath="url(#43fe28f9bf)">
            <g clipPath="url(#937013a8fb)">
              <path
                fill="url(#60fb04183a)"
                d="M 626.113281 88.585938 L 626.113281 163.1875 L 697.992188 163.1875 L 697.992188 88.585938 Z M 626.113281 88.585938 "
                fillRule="nonzero"
              />
            </g>
          </g>
          <path
            fill="#0bccc3"
            d="M 883.859375 640.875 C 883.808594 640.824219 883.757812 640.824219 883.707031 640.773438 C 883.605469 641.125 883.605469 641.429688 883.65625 641.734375 C 883.707031 641.429688 883.808594 641.125 883.859375 640.875 Z M 883.859375 640.875 "
            fillOpacity="1"
            fillRule="nonzero"
          />
          <g clipPath="url(#f6a15898e0)">
            <g clipPath="url(#0492e1310b)">
              <path
                fill="url(#681d653b62)"
                d="M 539.167969 11.8125 L 539.167969 655.734375 L 883.910156 655.734375 L 883.910156 11.8125 Z M 539.167969 11.8125 "
                fillRule="nonzero"
              />
            </g>
          </g>
          <g clipPath="url(#4cefb1fee7)">
            <g clipPath="url(#b005467ea3)">
              <path
                fill="url(#453db39e29)"
                d="M 516.847656 127 L 516.847656 658.3125 L 935.347656 658.3125 L 935.347656 127 Z M 516.847656 127 "
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
      </svg>

      <div className="w-[90vw] md:w-[600px] h-24 flex items-center justify-center">
        <svg className="w-full h-full" viewBox="0 0 600 100" preserveAspectRatio="xMidYMid meet">
          <defs>
            <linearGradient id="text-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" stopColor="#0bccc3" />
              <stop offset="100%" stopColor="#cc0b0b" />
            </linearGradient>
          </defs>
          <text
            x="50%"
            y="50%"
            textAnchor="middle"
            className="text-path"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            fontSize="48"
            fontWeight="bold"
            dominantBaseline="middle"
          >
            PLAYBACKS 2025
          </text>
          <text
            x="50%"
            y="50%"
            textAnchor="middle"
            className="text-gradient"
            fill="url(#text-gradient)"
            fontSize="48"
            fontWeight="bold"
            dominantBaseline="middle"
          >
            PLAYBACKS 2025
          </text>
        </svg>
      </div>
    </div>
  );
}
