import React, { useState, useEffect, useRef } from "react";
import { superadminApi } from "../../services/api";
import { io } from "socket.io-client";
import { API_CONFIG } from "../../config/api.config";
import { Bar } from "react-chartjs-2";
import { generateResultsPDF } from "../../utils/pdfGenerator";
import screenfull from "screenfull";
import WinnerReveal from "./WinnerReveal";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {
  PrinterIcon,
  EyeIcon,
  EyeSlashIcon,
  SignalIcon,
  SignalSlashIcon,
  ArrowsPointingOutIcon,
  ArrowsPointingInIcon,
} from "@heroicons/react/24/outline";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function VotingResults() {
  const [days, setDays] = useState([]);
  const [selectedDay, setSelectedDay] = useState("");
  const [results, setResults] = useState([]);
  const [socket, setSocket] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [showNames, setShowNames] = useState({});
  const [lastResults, setLastResults] = useState([]);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showWinner, setShowWinner] = useState(null);
  const resultsContainerRef = useRef(null);
  const socketRef = useRef(null);

  const requestFullscreen = () => {
    const element = resultsContainerRef.current;
    
    if (!element) return;

    try {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    } catch (error) {
      console.error('Error requesting fullscreen:', error);
    }
  };

  const exitFullscreen = () => {
    try {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    } catch (error) {
      console.error('Error exiting fullscreen:', error);
    }
  };

  const toggleFullscreen = () => {
    if (!screenfull.isEnabled) {
      if (!isFullscreen) {
        requestFullscreen();
      } else {
        exitFullscreen();
      }
      return;
    }

    if (!isFullscreen) {
      screenfull.request(resultsContainerRef.current);
    } else {
      screenfull.exit();
    }
  };

  useEffect(() => {
    loadDays();

    const handleFullscreenChange = () => {
      const isFullscreenNow = !!(
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement
      );
      setIsFullscreen(isFullscreenNow);
    };

    if (screenfull.isEnabled) {
      screenfull.on('change', handleFullscreenChange);
    }
    
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);
    document.addEventListener('MSFullscreenChange', handleFullscreenChange);

    return () => {
      if (screenfull.isEnabled) {
        screenfull.off('change', handleFullscreenChange);
      }
      
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
      document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
      
      cleanupSocket();
    };
  }, []);

  const cleanupSocket = () => {
    if (socketRef.current) {
      socketRef.current.disconnect();
      socketRef.current.removeAllListeners();
      socketRef.current = null;
      setSocket(null);
      setIsConnected(false);
    }
  };

  const initializeSocket = (day) => {
    cleanupSocket();

    const socketUrl = `${API_CONFIG.BASE_URL}${API_CONFIG.ENDPOINTS.VOTE.RESULTS_LIVE}`;
    const newSocket = io(socketUrl, {
      withCredentials: true,
      transports: ["websocket"],
    });

    newSocket.on("connect", () => {
      setIsConnected(true);
      newSocket.emit("subscribeToResults", {
        classification_day_id: day,
      });
    });

    newSocket.on("disconnect", () => {
      setIsConnected(false);
    });

    newSocket.on("votingResultsUpdated", (data) => {
      const sortedResults = data.results.sort(
        (a, b) => b.total_votes - a.total_votes
      );
      setResults(sortedResults);
      setLastResults(sortedResults);
    });

    socketRef.current = newSocket;
    setSocket(newSocket);
  };

  useEffect(() => {
    if (selectedDay) {
      initializeSocket(selectedDay);
    }

    return () => {
      cleanupSocket();
    };
  }, [selectedDay]);

  const loadDays = async () => {
    try {
      const response = await superadminApi.classificationDays.getAll();
      setDays(response.data);
    } catch (error) {
      console.error("Error loading days:", error);
    }
  };

  const handleDayChange = (e) => {
    const newDay = e.target.value;
    setSelectedDay(newDay);
    setResults([]);
    setShowNames({});
  };

  const toggleConnection = () => {
    if (isConnected) {
      cleanupSocket();
    } else if (selectedDay) {
      initializeSocket(selectedDay);
    }
  };

  const toggleShowName = (index) => {
    setShowNames((prev) => {
      const newState = {
        ...prev,
        [index]: !prev[index],
      };
      
      if (!prev[index] && results[index]) {
        setShowWinner({ ...results[index], position: index + 1 });
      }
      
      return newState;
    });
  };

  const handlePrintResults = () => {
    if (results.length === 0 || !selectedDay) return;

    const dayInfo = days.find((day) => day.id === parseInt(selectedDay));
    if (!dayInfo) return;

    generateResultsPDF(results, dayInfo);
  };

  const chartData = {
    labels: results.map((_, index) =>
      showNames[index] ? results[index].contestant_name : `${index + 1}º`
    ),
    datasets: [
      {
        data: results.map((r) => r.total_votes),
        backgroundColor: [
          "rgba(255, 99, 132, 0.8)",
          "rgba(54, 162, 235, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(75, 192, 192, 0.8)",
          "rgba(153, 102, 255, 0.8)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const result = results[context.dataIndex];
            const position = context.dataIndex + 1;
            return showNames[context.dataIndex]
              ? `${result.contestant_name} - ${result.total_votes} votos`
              : `${position}º Posición - ${result.total_votes} votos`;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
        },
      },
    },
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6">
      <div
        ref={resultsContainerRef}
        className={`flex flex-col space-y-6 ${
          isFullscreen
            ? "fixed inset-0 bg-white dark:bg-gray-800 z-[9999] p-6 overflow-auto"
            : ""
        }`}
        style={{
          touchAction: 'manipulation',
          minHeight: isFullscreen ? '100vh' : 'auto',
          width: isFullscreen ? '100vw' : 'auto',
          WebkitOverflowScrolling: 'touch',
          position: isFullscreen ? 'fixed' : 'relative',
          top: isFullscreen ? '0' : 'auto',
          left: isFullscreen ? '0' : 'auto',
        }}
      >
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0">
          <div className="flex flex-wrap items-center gap-2 w-full sm:w-auto">
            <button
              onClick={toggleConnection}
              className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${
                isConnected
                  ? "bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200"
                  : "bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200"
              }`}
            >
              {isConnected ? (
                <SignalIcon className="w-4 h-4 mr-1" />
              ) : (
                <SignalSlashIcon className="w-4 h-4 mr-1" />
              )}
              {isConnected ? "Conectado" : "Desconectado"}
            </button>
            <button
              onClick={handlePrintResults}
              disabled={!results.length}
              className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium 
                ${
                  results.length
                    ? "bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-200 hover:bg-opacity-75"
                    : "bg-gray-100 text-gray-400 cursor-not-allowed"
                } transition-colors`}
            >
              <PrinterIcon className="w-4 h-4 mr-1" />
              Imprimir
            </button>
            <button
              onClick={toggleFullscreen}
              className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-indigo-100 text-indigo-800 dark:bg-indigo-900 dark:text-indigo-200 hover:bg-opacity-75"
            >
              {isFullscreen ? (
                <ArrowsPointingInIcon className="w-4 h-4 mr-1" />
              ) : (
                <ArrowsPointingOutIcon className="w-4 h-4 mr-1" />
              )}
              {isFullscreen ? "Salir" : "Pantalla Completa"}
            </button>
          </div>
        </div>

        {!isFullscreen && (
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
            <div className="col-span-1">
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Seleccionar Día
              </label>
              <select
                value={selectedDay}
                onChange={handleDayChange}
                className="form-select"
              >
                <option value="">Seleccione un día</option>
                {days.map((day) => (
                  <option key={day.id} value={day.id}>
                    {new Date(day.date).toLocaleDateString()} -{" "}
                    {day.description}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}

        <div
          className={`${isFullscreen ? "h-[60vh]" : "h-[400px]"} relative mb-6`}
        >
          {results.length > 0 ? (
            <Bar data={chartData} options={chartOptions} />
          ) : (
            <div className="absolute inset-0 flex items-center justify-center">
              <p className="text-gray-500 dark:text-gray-400">
                Seleccione un día para ver los resultados
              </p>
            </div>
          )}
        </div>

        {results.length > 0 && (
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
            {results.map((result, index) => {
              const colorIndex =
                index % chartData.datasets[0].backgroundColor.length;
              const borderColor = chartData.datasets[0].borderColor[colorIndex];
              const bgColor = chartData.datasets[0].backgroundColor[colorIndex];

              return (
                <div
                  key={index}
                  className="bg-gray-50 dark:bg-gray-700 rounded-lg p-4 relative hover:-translate-y-1"
                  style={{
                    borderRight: `4px solid ${borderColor}`,
                    borderBottom: `4px solid ${borderColor}`,
                    transform: "translate(-4px, -4px)",
                    transition: "all 0.2s ease",
                    boxShadow: `4px 4px 0 ${bgColor}`,
                  }}
                >
                  <div className="flex justify-between items-start mb-2">
                    <span
                      className={`text-2xl font-bold ${
                        index === 0
                          ? "text-yellow-500"
                          : index === 1
                          ? "text-gray-400"
                          : index === 2
                          ? "text-amber-600"
                          : "text-gray-700 dark:text-gray-300"
                      }`}
                    >
                      {index + 1}º
                    </span>
                    <button
                      onClick={() => toggleShowName(index)}
                      className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300"
                    >
                      {showNames[index] ? (
                        <EyeSlashIcon className="w-5 h-5" />
                      ) : (
                        <EyeIcon className="w-5 h-5" />
                      )}
                    </button>
                  </div>
                  <div className="space-y-1">
                    <p className="text-sm font-medium text-gray-600 dark:text-gray-300">
                      {showNames[index] ? result.contestant_name : "* * * * *"}
                    </p>
                    <p
                      className="text-lg font-semibold"
                      style={{ color: borderColor }}
                    >
                      {result.total_votes} votos
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {showWinner && (
          <WinnerReveal
            winner={showWinner}
            position={showWinner.position}
            onClose={() => setShowWinner(null)}
          />
        )}
      </div>
    </div>
  );
}

export default VotingResults;
