import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import confetti from 'canvas-confetti';

function WinnerReveal({ winner, position, onClose }) {
  const triggerConfetti = () => {
    if (position > 4) return; // Solo confeti hasta el 4º puesto
    
    const duration = 3000;
    const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

    function randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    }

    const interval = setInterval(function() {
      const timeLeft = duration - Date.now();

      if (timeLeft <= 0) {
        return clearInterval(interval);
      }

      const particleCount = 50 * (timeLeft / duration);
      
      // Confeti desde los lados
      confetti(Object.assign({}, defaults, { 
        particleCount,
        origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
        colors: position === 1 ? ['#FFD700'] : position === 2 ? ['#C0C0C0'] : position === 3 ? ['#CD7F32'] : ['#4CAF50']
      }));
      confetti(Object.assign({}, defaults, { 
        particleCount,
        origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
        colors: position === 1 ? ['#FFD700'] : position === 2 ? ['#C0C0C0'] : position === 3 ? ['#CD7F32'] : ['#4CAF50']
      }));
    }, 250);
  };

  React.useEffect(() => {
    if (winner) {
      triggerConfetti();
    }
  }, [winner, position]);

  const getPositionStyle = () => {
    switch (position) {
      case 1:
        return "from-yellow-400 via-yellow-500 to-yellow-600";
      case 2:
        return "from-gray-300 via-gray-400 to-gray-500";
      case 3:
        return "from-amber-600 via-amber-700 to-amber-800";
      case 4:
        return "from-green-400 via-green-500 to-green-600";
      default:
        return "from-blue-400 via-blue-500 to-blue-600";
    }
  };

  const getPositionEmoji = () => {
    switch (position) {
      case 1:
        return "🏆";
      case 2:
        return "🥈";
      case 3:
        return "🥉";
      case 4:
        return "🎭";
      default:
        return "🎯";
    }
  };

  const getPrizeMoney = () => {
    switch (position) {
      case 1:
        return "1.000€";
      case 2:
        return "600€";
      case 3:
        return "400€";
      case 4:
        return "200€";
      default:
        return "";
    }
  };

  const getPositionText = () => {
    switch (position) {
      case 1:
        return "¡PRIMER PREMIO!";
      case 2:
        return "¡SEGUNDO PREMIO!";
      case 3:
        return "¡TERCER PREMIO!";
      case 4:
        return "¡CUARTO PREMIO!";
      default:
        return `¡${position}º LUGAR!`;
    }
  };

  const getTextColor = () => {
    switch (position) {
      case 1:
        return "text-yellow-500";
      case 2:
        return "text-gray-500";
      case 3:
        return "text-amber-600";
      case 4:
        return "text-green-500";
      default:
        return "text-blue-500";
    }
  };

  return (
    <AnimatePresence>
      {winner && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70"
          onClick={onClose}
        >
          <motion.div
            initial={{ scale: 0, rotate: -180 }}
            animate={{ 
              scale: 1, 
              rotate: 0,
              transition: { 
                type: "spring",
                stiffness: 200,
                damping: 20
              }
            }}
            exit={{ scale: 0, rotate: 180 }}
            className={`bg-gradient-to-r ${getPositionStyle()} p-1 rounded-2xl shadow-xl`}
          >
            <div className="bg-white dark:bg-gray-800 rounded-xl p-8 text-center">
              <motion.div
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.2 }}
                className="mb-4"
              >
                <h2 className={`text-3xl font-bold ${getTextColor()} mb-2`}>
                  {getPositionEmoji()} {getPositionText()} {getPositionEmoji()}
                </h2>
                <p className={`text-2xl font-bold ${getTextColor()}`}>
                  {getPrizeMoney()}
                </p>
              </motion.div>
              
              <motion.div
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.4 }}
                className="mb-6"
              >
                <p className="text-4xl font-bold text-gray-800 dark:text-white mb-2">
                  {winner.contestant_name}
                </p>
                <p className="text-2xl text-gray-600 dark:text-gray-300">
                  {winner.total_votes} votos
                </p>
              </motion.div>

              <motion.button
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.6 }}
                onClick={onClose}
                className={`px-6 py-2 text-white rounded-full transition-colors bg-${
                  position === 1 ? 'yellow' : 
                  position === 2 ? 'gray' : 
                  position === 3 ? 'amber' : 
                  position === 4 ? 'green' : 
                  'blue'
                }-500 hover:bg-${
                  position === 1 ? 'yellow' : 
                  position === 2 ? 'gray' : 
                  position === 3 ? 'amber' : 
                  position === 4 ? 'green' : 
                  'blue'
                }-600`}
              >
                Cerrar
              </motion.button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default WinnerReveal;