import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import { superadminApi } from '../services/api';

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    role: localStorage.getItem('role'),
    authenticated: localStorage.getItem('authenticated') === 'true',
    isLoading: false
  });
  
  const checkInProgress = useRef(false);

  const verifyAuth = async () => {
    if (checkInProgress.current) return;
    if (!auth.authenticated) {
      setAuth(prev => ({ ...prev, isLoading: false }));
      return;
    }

    try {
      checkInProgress.current = true;
      setAuth(prev => ({ ...prev, isLoading: true }));
      
      const response = await superadminApi.users.verify();
      const { role, authenticated } = response.data;
      
      if (!authenticated) {
        logout();
      } else {
        setAuth({
          role,
          authenticated,
          isLoading: false
        });
      }
    } catch (error) {
      logout();
    } finally {
      checkInProgress.current = false;
    }
  };

  const login = async (credentials) => {
    try {
      const response = await superadminApi.users.login(credentials);
      const { role, authenticated } = response.data;
      
      localStorage.setItem('role', role);
      localStorage.setItem('authenticated', authenticated);
      setAuth({ role, authenticated, isLoading: false });
      return { success: true };
    } catch (error) {
      return {
        success: false,
        message: error.response?.data?.message || 'An error occurred'
      };
    }
  };

  const logout = () => {
    localStorage.removeItem('role');
    localStorage.removeItem('authenticated');
    setAuth({ role: null, authenticated: false, isLoading: false });
  };

  return (
    <AuthContext.Provider value={{ auth, login, logout, verifyAuth }}>
      {children}
    </AuthContext.Provider>
  );
};