import React, { useState, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ExclamationTriangleIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import Html5QrcodePlugin from './QRScanner';
import { adminApi } from '../services/api';

function EntryValidator() {
  const [entryNumber, setEntryNumber] = useState('');
  const [isScanning, setIsScanning] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isValidating, setIsValidating] = useState(false);
  const scannerRef = useRef(null); // Ref for the QRScanner component

  const extractCodeFromUrl = (url) => {
    try {
      const urlObj = new URL(url);
      return urlObj.searchParams.get('code')?.trim() || url.trim();
    } catch {
      return url.trim();
    }
  };

  const handleValidation = async (number) => {
    if (!number) {
      setError('Por favor, ingrese un número de entrada válido');
      return;
    }

    if (isValidating) return;

    try {
      setIsValidating(true);
      const response = await adminApi.validateEntry(number);

      setSuccess(response.data.message);
      setEntryNumber('');

      setTimeout(() => {
        setSuccess('');
        setIsValidating(false);
        scannerRef.current?.resumeScanner();
      }, 1000);
    } catch (error) {
      setError(error.response?.data?.message || 'Error al validar la entrada');
      setIsValidating(false);
    }
  };

  const handleScanSuccess = (decodedText) => {
    if (isValidating) return;

    const code = extractCodeFromUrl(decodedText);
    if (code) {
      setEntryNumber(code);
      handleValidation(code);
    } else {
      setError('Código QR inválido');
    }
  };

  const closeError = () => {
    setError('');
    scannerRef.current?.resumeScanner(); // Resume scanning
  };

  const closeSuccess = () => {
    setSuccess('');
    scannerRef.current?.resumeScanner(); // Resume scanning
  };

  return (
    <div className="bg-white dark:bg-gray-800 shadow-lg rounded-lg p-6">
      {!isScanning ? (
        <div className="space-y-4">
          <div className="form-group">
            <label
              htmlFor="entryNumber"
              className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-2"
            >
              Número de Entrada
            </label>
            <div className="flex gap-2">
              <input
                type="text"
                id="entryNumber"
                className="form-input flex-1"
                placeholder="Ingrese el número de entrada"
                value={entryNumber}
                onChange={(e) => setEntryNumber(e.target.value)}
                disabled={isValidating}
              />
              <button
                onClick={() => handleValidation(entryNumber)}
                disabled={isValidating || !entryNumber}
                className={`px-6 py-2 bg-indigo-600 hover:bg-indigo-700 dark:bg-indigo-500 dark:hover:bg-indigo-600 text-white font-medium rounded-lg transition-colors duration-200 ${
                  (isValidating || !entryNumber) ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              >
                {isValidating ? 'Validando...' : 'Validar'}
              </button>
            </div>
          </div>

          <button
            onClick={() => setIsScanning(true)}
            disabled={isValidating}
            className={`w-full py-3 bg-emerald-600 hover:bg-emerald-700 dark:bg-emerald-500 dark:hover:bg-emerald-600 text-white font-medium rounded-lg transition-colors duration-200 flex items-center justify-center gap-2 ${
              isValidating ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M3 4a1 1 0 011-1h3a1 1 0 011 1v3a1 1 0 01-1 1H4a1 1 0 01-1-1V4zm2 2V5h1v1H5zM3 13a1 1 0 011-1h3a1 1 0 011 1v3a1 1 0 01-1 1H4a1 1 0 01-1-1v-3zm2 2v-1h1v1H5zM13 3a1 1 0 00-1 1v3a1 1 0 001 1h3a1 1 0 001-1V4a1 1 0 00-1-1h-3zm1 2v1h1V5h-1z" clipRule="evenodd" />
              <path d="M11 4a1 1 0 10-2 0v1a1 1 0 002 0V4zM10 7a1 1 0 011 1v1h2a1 1 0 110 2h-3a1 1 0 01-1-1V8a1 1 0 011-1zM16 9a1 1 0 100 2 1 1 0 000-2zM9 13a1 1 0 011-1h1a1 1 0 110 2v2a1 1 0 11-2 0v-3zM7 11a1 1 0 100-2H4a1 1 0 100 2h3z" />
            </svg>
            Escanear QR
          </button>
        </div>
      ) : (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center p-4 z-50">
          <div className="bg-white dark:bg-gray-800 rounded-lg p-6 w-full max-w-md">
            <div className="mb-4 text-center">
              <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                Escanear Código QR
              </h3>
            </div>

            <Html5QrcodePlugin
              ref={scannerRef} // Pass the ref
              fps={10}
              qrbox={250}
              disableFlip={false}
              qrCodeSuccessCallback={handleScanSuccess}
            />

            <button
              onClick={() => setIsScanning(false)}
              className="mt-4 w-full px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
            >
              Cerrar
            </button>
          </div>
        </div>
      )}

      <AnimatePresence>
        {error && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={closeError}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
          >
            <motion.div
              initial={{ scale: 0.95, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.95, opacity: 0 }}
              onClick={(e) => e.stopPropagation()}
              className="bg-white dark:bg-gray-800 rounded-lg p-6 max-w-sm w-full shadow-xl"
            >
              <div className="flex flex-col items-center text-center">
                <ExclamationTriangleIcon className="h-12 w-12 text-red-500 mb-4" />
                <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2">
                  Error
                </h3>
                <p className="text-gray-500 dark:text-gray-400 mb-6">{error}</p>
                <button
                  onClick={closeError}
                  className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
                >
                  Cerrar
                </button>
              </div>
            </motion.div>
          </motion.div>
        )}

        {success && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={closeSuccess}
            className="fixed inset-0 bg-green-500 bg-opacity-20 backdrop-blur-sm flex items-center justify-center z-50"
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              className="bg-white dark:bg-gray-800 rounded-lg p-8 shadow-2xl"
            >
              <div className="flex flex-col items-center text-center">
                <CheckCircleIcon className="h-16 w-16 text-green-500 mb-4" />
                <h3 className="text-xl font-medium text-gray-900 dark:text-white mb-2">
                  ¡Éxito!
                </h3>
                <p className="text-gray-600 dark:text-gray-300">{success}</p>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default EntryValidator;
