import React, { useState, useEffect } from 'react';
import { superadminApi } from '../../services/api';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { ChevronDownIcon, ChevronUpIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';

function VoteResults() {
  const [days, setDays] = useState([]);
  const [selectedDay, setSelectedDay] = useState('');
  const [results, setResults] = useState([]);
  const [expandedEntry, setExpandedEntry] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    loadDays();
  }, []);

  useEffect(() => {
    if (selectedDay) {
      loadResults();
    }
  }, [selectedDay]);

  const loadDays = async () => {
    try {
      const response = await superadminApi.classificationDays.getAll();
      setDays(response.data);
    } catch (error) {
      setError('Error al cargar los días de clasificación');
    }
  };

  const loadResults = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await superadminApi.votes.getResults(selectedDay);
      setResults(response.data);
    } catch (error) {
      setError('Error al cargar los resultados de votos');
      console.error('Error loading vote results:', error);
    } finally {
      setLoading(false);
    }
  };

  const toggleExpand = (entryId) => {
    setExpandedEntry(expandedEntry === entryId ? null : entryId);
  };

  const formatDateTime = (dateString) => {
    return format(new Date(dateString), 'dd/MM/yyyy HH:mm:ss');
  };

  const filteredResults = results.filter(entry => 
    entry.entry_number.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow p-4 sm:p-6">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-6">
        <div className="w-full sm:w-auto flex flex-col sm:flex-row gap-4">
          <div className="w-full sm:w-64">
            <select
              value={selectedDay}
              onChange={(e) => setSelectedDay(e.target.value)}
              className="form-select w-full"
            >
              <option value="">Seleccione un día</option>
              {days.map((day) => (
                <option key={day.id} value={day.id}>
                  {format(new Date(day.date), 'dd/MM/yyyy')} - {day.description}
                </option>
              ))}
            </select>
          </div>
          {results.length > 0 && (
            <div className="relative w-full sm:w-64">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                placeholder="Buscar por número de entrada..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="form-input pl-10 w-full"
              />
            </div>
          )}
        </div>
      </div>

      {loading && (
        <div className="flex justify-center items-center py-8">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
        </div>
      )}

      {error && (
        <div className="bg-red-50 dark:bg-red-900/30 text-red-800 dark:text-red-200 p-4 rounded-lg mb-6">
          {error}
        </div>
      )}

      {!loading && !error && !selectedDay && (
        <div className="text-center py-8 text-gray-500 dark:text-gray-400">
          Seleccione un día para ver los resultados
        </div>
      )}

      {!loading && !error && selectedDay && results.length === 0 && (
        <div className="text-center py-8 text-gray-500 dark:text-gray-400">
          No hay resultados para el día seleccionado
        </div>
      )}

      {!loading && !error && results.length > 0 && (
        <div className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-hidden border border-gray-200 dark:border-gray-700 sm:rounded-lg">
              <div className="max-h-[600px] overflow-y-auto">
                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                  <thead className="bg-gray-50 dark:bg-gray-700 sticky top-0 z-10">
                    <tr>
                      <th scope="col" className="px-4 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                        Entrada
                      </th>
                      <th scope="col" className="hidden sm:table-cell px-4 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                        Fecha y Hora
                      </th>
                      <th scope="col" className="px-4 sm:px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                        Total
                      </th>
                      <th scope="col" className="px-4 sm:px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
                        Detalles
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                    {filteredResults.map((entry) => (
                      <React.Fragment key={entry.entry_id}>
                        <tr className="hover:bg-gray-50 dark:hover:bg-gray-700">
                          <td className="px-4 sm:px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-white">
                            {entry.entry_number}
                          </td>
                          <td className="hidden sm:table-cell px-4 sm:px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
                            {formatDateTime(entry.vote_date)}
                          </td>
                          <td className="px-4 sm:px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
                            {entry.votes.reduce((sum, vote) => sum + vote.vote_value, 0)}
                          </td>
                          <td className="px-4 sm:px-6 py-4 whitespace-nowrap text-sm text-right">
                            <button
                              onClick={() => toggleExpand(entry.entry_id)}
                              className="inline-flex items-center text-indigo-600 hover:text-indigo-900 dark:text-indigo-400 dark:hover:text-indigo-300"
                            >
                              {expandedEntry === entry.entry_id ? (
                                <>
                                  <ChevronUpIcon className="h-5 w-5 mr-1" />
                                  <span className="hidden sm:inline">Ocultar</span>
                                </>
                              ) : (
                                <>
                                  <ChevronDownIcon className="h-5 w-5 mr-1" />
                                  <span className="hidden sm:inline">Ver votos</span>
                                </>
                              )}
                            </button>
                          </td>
                        </tr>
                        {expandedEntry === entry.entry_id && (
                          <tr className="bg-gray-50 dark:bg-gray-700/50">
                            <td colSpan="4" className="px-4 sm:px-6 py-4">
                              <div className="border-l-4 border-indigo-500 pl-4">
                                <h4 className="text-sm font-medium text-gray-900 dark:text-white mb-2">
                                  Detalle de votos
                                </h4>
                                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                                  {entry.votes.map((vote, index) => (
                                    <div
                                      key={index}
                                      className="bg-white dark:bg-gray-800 rounded-lg p-3 shadow-sm"
                                    >
                                      <div className="text-sm font-medium text-gray-900 dark:text-white">
                                        {vote.contestant_name}
                                      </div>
                                      <div className="text-sm text-gray-500 dark:text-gray-400">
                                        {vote.vote_value} {vote.vote_value === 1 ? 'voto' : 'votos'}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default VoteResults;
