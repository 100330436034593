import React, { useState } from "react";
import { TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { superadminApi } from "../../services/api";

function CacheClearButton() {
  const [showModal, setShowModal] = useState(false);
  const [customPath, setCustomPath] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: "", text: "" });

  const handleClearCache = async (type) => {
    try {
      setLoading(true);
      let payload = { type };

      if (type === "custom") {
        if (!customPath) {
          setMessage({
            type: "error",
            text: "Por favor, ingrese una ruta personalizada",
          });
          return;
        }
        payload.customPath = customPath;
      }

      await superadminApi.cloudfront.clearCache(payload);
      setMessage({ type: "success", text: "Cache limpiada exitosamente" });
      setShowModal(false);
      setCustomPath("");
    } catch (error) {
      setMessage({
        type: "error",
        text: error.response?.data?.message || "Error al limpiar la cache",
      });
    } finally {
      setLoading(false);
      setTimeout(() => setMessage({ type: "", text: "" }), 3000);
    }
  };

  const handleLocalCacheClear = () => {
    const authData = {
      role: localStorage.getItem("role"),
      authenticated: localStorage.getItem("authenticated"),
    };
    localStorage.clear();
    localStorage.setItem("role", authData.role);
    localStorage.setItem("authenticated", authData.authenticated);
    setMessage({ type: "success", text: "Cache local limpiada exitosamente" });
    setTimeout(() => setMessage({ type: "", text: "" }), 3000);
    setShowModal(false);
  };

  return (
    <>
      <button
        onClick={() => setShowModal(true)}
        className="w-full flex items-center space-x-2 px-4 py-2 rounded-lg text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200"
        disabled={loading}
      >
        <TrashIcon className="w-5 h-5" />
        <span className="flex-grow text-left">Limpiar Cache</span>
      </button>

      {showModal && (
        <div className="fixed inset-0 z-[100] overflow-y-auto">
          <div className="flex min-h-screen items-center justify-center p-4">
            <div
              className="fixed inset-0 bg-black bg-opacity-25"
              onClick={() => setShowModal(false)}
            />

            <div className="relative bg-white dark:bg-gray-800 rounded-lg shadow-xl w-full max-w-sm mx-auto">
              <div className="flex items-center justify-between p-4 border-b border-gray-200 dark:border-gray-700">
                <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                  Opciones de Cache
                </h3>
                <button
                  onClick={() => setShowModal(false)}
                  className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
                >
                  <XMarkIcon className="h-5 w-5" />
                </button>
              </div>

              <div className="p-4 space-y-4">
                <div>
                  <div className="text-xs font-medium text-gray-500 dark:text-gray-400 uppercase mb-2">
                    Cache Local
                  </div>
                  <button
                    onClick={handleLocalCacheClear}
                    className="w-full text-left p-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-md flex items-center"
                  >
                    <TrashIcon className="h-4 w-4 mr-2" />
                    Local
                  </button>
                </div>

                <div>
                  <div className="text-xs font-medium text-gray-500 dark:text-gray-400 uppercase mb-2">
                    Cache CDN
                  </div>
                  <div className="space-y-2">
                    <button
                      onClick={() => handleClearCache("all")}
                      className="w-full text-left p-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-md flex items-center"
                    >
                      <TrashIcon className="h-4 w-4 mr-2" />
                      Todo
                    </button>

                    <button
                      onClick={() => handleClearCache("backend")}
                      className="w-full text-left p-2 text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-md flex items-center"
                    >
                      <TrashIcon className="h-4 w-4 mr-2" />
                      Backend
                    </button>

                    <div className="space-y-2">
                      <input
                        type="text"
                        value={customPath}
                        onChange={(e) => setCustomPath(e.target.value)}
                        placeholder="Ruta personalizada"
                        className="w-full px-3 py-2 text-sm border border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-white focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-400"
                      />
                      <button
                        onClick={() => handleClearCache("custom")}
                        disabled={!customPath}
                        className={`w-full p-2 text-sm rounded-md transition-colors ${
                          customPath
                            ? "text-white bg-indigo-600 hover:bg-indigo-700 dark:bg-indigo-500 dark:hover:bg-indigo-600"
                            : "text-gray-400 bg-gray-100 dark:bg-gray-700 cursor-not-allowed"
                        }`}
                      >
                        Limpiar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {message.text && (
        <div className="fixed inset-x-0 bottom-20 sm:bottom-8 z-[110] flex justify-center">
          <div
            className={`
      w-[320px] rounded-lg shadow-xl p-4 text-center font-medium
      ${
        message.type === "success"
          ? "bg-green-50/95 text-green-800 dark:bg-green-900/95 dark:text-green-200 border border-green-200 dark:border-green-800"
          : "bg-red-50/95 text-red-800 dark:bg-red-900/95 dark:text-red-200 border border-red-200 dark:border-red-800"
      }
    `}
          >
            {message.text}
          </div>
        </div>
      )}

      {loading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[120]">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-white"></div>
        </div>
      )}
    </>
  );
}

export default CacheClearButton;
