import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const PrivateRoute = ({ children }) => {
  const { auth, verifyAuth } = useAuth();

  useEffect(() => {
    verifyAuth();
  }, []);

  if (auth.isLoading) {
    return <div className="min-h-screen flex items-center justify-center">
      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
    </div>;
  }

  if (!auth.authenticated) {
    return <Navigate to="/user/login" replace />;
  }

  return children;
}

export default PrivateRoute;