import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import {
  QrCodeIcon,
  ExclamationTriangleIcon,
  CheckCircleIcon,
  XMarkIcon
} from "@heroicons/react/24/outline";
import { contestApi } from "../services/api";
import Html5QrcodePlugin from './QRScanner';
import VoteAnimation from "./VoteAnimation";
import confetti from "canvas-confetti";

const VOTE_VALUES = [5, 3, 1];
const MEDAL_COLORS = {
  5: "gold",
  3: "silver",
  1: "bronze",
};

const MEDAL_EMOJIS = {
  5: "🥇",
  3: "🥈",
  1: "🥉"
};

function VotingForm() {
  const [contestants, setContestants] = useState([]);
  const [entryNumber, setEntryNumber] = useState("");
  const [votes, setVotes] = useState([]);
  const [error, setError] = useState("");
  const [isScanning, setIsScanning] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [submittedVotes, setSubmittedVotes] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const loadContestants = async () => {
      try {
        const data = await contestApi.getContestantsToday();
        setContestants(data.sort((a, b) => a.order - b.order));
      } catch (err) {
        setError("Error al cargar los concursantes");
      }
    };

    loadContestants();

    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");
    if (code) {
      setEntryNumber(code.trim());
      navigate("/", { replace: true });
    }
  }, [location, navigate]);

  const triggerConfetti = () => {
    const duration = 1000;
    const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };
    const randomInRange = (min, max) => Math.random() * (max - min) + min;

    const interval = setInterval(function () {
      const timeLeft = duration - Date.now();
      if (timeLeft <= 0) return clearInterval(interval);

      const particleCount = 50 * (timeLeft / duration);
      confetti({
        ...defaults,
        particleCount,
        origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
      });
      confetti({
        ...defaults,
        particleCount,
        origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
      });
    }, 250);
  };

  const handleVoteClick = (contestantId) => {
    setVotes((prevVotes) => {
      const existingVoteIndex = prevVotes.findIndex(
        (v) => v.contestant_id === contestantId
      );

      if (existingVoteIndex !== -1) {
        return prevVotes.filter((v) => v.contestant_id !== contestantId);
      }

      if (prevVotes.length >= 3) {
        setError(
          "Ya has seleccionado 3 grupos. Debes quitar uno antes de añadir otro."
        );
        return prevVotes;
      }

      const usedValues = prevVotes.map((v) => v.vote_value);
      const availableValues = VOTE_VALUES.filter(
        (v) => !usedValues.includes(v)
      );
      const highestAvailableValue = Math.max(...availableValues);

      if (highestAvailableValue === 5) {
        triggerConfetti();
      }

      return [
        ...prevVotes,
        {
          contestant_id: contestantId,
          vote_value: highestAvailableValue,
        },
      ];
    });
  };

  const getVoteValue = (contestantId) => {
    const vote = votes.find((v) => v.contestant_id === contestantId);
    return vote ? vote.vote_value : null;
  };

  const handleSubmit = async () => {
    if (!entryNumber) {
      setError("Por favor, ingresa tu número de entrada");
      return;
    }

    if (votes.length !== 3) {
      setError("Debes seleccionar exactamente 3 grupos");
      return;
    }

    try {
      setSubmitting(true);
       // Esperar 2 segundos para que la animación del sobre se complete
      await new Promise(resolve => setTimeout(resolve, 2000));
      
      await contestApi.createVotes(entryNumber.trim(), votes);
      
      const votesWithDetails = votes.map(vote => ({
        ...vote,
        contestant_name: contestants.find(c => c.id === vote.contestant_id)?.contestant_name
      })).sort((a, b) => b.vote_value - a.vote_value);
      
      setSubmittedVotes(votesWithDetails);
      setShowSuccessModal(true);
      setVotes([]);
      setEntryNumber("");
    } catch (error) {
      setError(error.response?.data?.message || "Error al enviar los votos");
    } finally {
      setSubmitting(false);
    }
  };

  const handleQRCodeScan = (decodedText) => {
    try {
      const url = new URL(decodedText);
      const code = url.searchParams.get("code");
      if (code) {
        setEntryNumber(code.trim());
      } else {
        setEntryNumber(decodedText.trim());
      }
      setIsScanning(false);
    } catch {
      setEntryNumber(decodedText.trim());
      setIsScanning(false);
    }
  };

  const handleScanError = (error) => {
    if (!error.toString().includes('NotFoundException')) {
      console.error("Error escaneando:", error);
    }
  };

  const handleCloseSuccess = () => {
    setShowSuccessModal(false);
    setSubmittedVotes([]);
  };

  const getActiveModal = () => {
    if (submitting) return "submitting";
    if (isScanning) return "scanning";
    if (error) return "error";
    if (showSuccessModal) return "success";
    return null;
  };

  return (
    <div className="max-w-6xl mx-auto px-4 py-8 pb-32">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="mb-12 text-center px-4 sm:px-0"
      >
        <motion.div
          className="flex flex-col items-center justify-center sm:flex-row sm:space-x-6 sm:items-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          style={{ overflow: "visible" }}
        >
          <motion.div
            className="text-[4rem] sm:text-[5rem] md:text-[6rem] lg:text-[7rem] font-black bg-gradient-to-r from-purple-600 via-pink-500 to-red-500 text-transparent bg-clip-text"
            style={{
              lineHeight: "1.2",
              paddingBottom: "0.2rem",
            }}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
          >
            <span className="hidden sm:inline text-yellow-400 mr-4 text-5xl">
              ✨
            </span>
            Playbacks
          </motion.div>
          <motion.div
            className="text-[4rem] sm:text-[5rem] md:text-[6rem] lg:text-[7rem] font-black bg-gradient-to-r from-purple-600 via-pink-500 to-red-500 text-transparent bg-clip-text"
            style={{ lineHeight: "1.2" }}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6 }}
          >
            2025
            <span className="hidden sm:inline text-yellow-400 ml-4 text-5xl">
              ✨
            </span>
          </motion.div>
        </motion.div>

        <motion.p
          className="text-2xl sm:text-3xl md:text-4xl font-bold bg-gradient-to-r from-indigo-400 via-purple-400 to-pink-400 text-transparent bg-clip-text mt-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.8 }}
        >
          ¡Vota por tus actuaciones favoritas!
        </motion.p>

        <motion.p
          className="text-lg sm:text-xl md:text-2xl text-gray-400 mt-4"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 1 }}
        >
          Selecciona los 3 mejores shows en orden de preferencia
        </motion.p>
      </motion.div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 px-4 sm:px-0 mb-8">
        {contestants.map((contestant, index) => {
          const voteValue = getVoteValue(contestant.id);
          return (
            <motion.div
              key={`contestant-${contestant.id}`}
              layout
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 + 1.2 }}
              exit={{ opacity: 0, y: -20 }}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              className={`
                relative overflow-hidden rounded-xl shadow-lg transition-all duration-300
                ${
                  voteValue
                    ? `ring-4 ring-${MEDAL_COLORS[voteValue]}-400 dark:ring-${MEDAL_COLORS[voteValue]}-600`
                    : "hover:shadow-xl"
                }
                ${
                  voteValue
                    ? "bg-gradient-to-br from-white to-gray-100 dark:from-gray-800 dark:to-gray-900"
                    : "bg-white dark:bg-gray-800"
                }
              `}
            >
              <button
                onClick={() => handleVoteClick(contestant.id)}
                className="w-full h-full p-6 text-left"
              >
                <div className="flex items-center justify-between mb-4">
                  <span className="text-2xl font-bold text-gray-900 dark:text-white">
                    #{contestant.order}
                  </span>
                  {voteValue && (
                    <motion.div
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      className={`
                        flex items-center justify-center w-12 h-12 rounded-full
                        bg-${MEDAL_COLORS[voteValue]}-100 dark:bg-${MEDAL_COLORS[voteValue]}-900
                        text-${MEDAL_COLORS[voteValue]}-600 dark:text-${MEDAL_COLORS[voteValue]}-400
                      `}
                    >
                      <span className="text-xl font-bold">{voteValue}</span>
                    </motion.div>
                  )}
                </div>

                <h3 className="text-xl font-semibold text-gray-800 dark:text-white mb-2">
                  {contestant.contestant_name}
                </h3>

                {voteValue && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className="absolute bottom-0 left-0 w-full h-1"
                    style={{
                      background: `linear-gradient(to right, ${MEDAL_COLORS[voteValue]}, transparent)`,
                    }}
                  />
                )}
              </button>
            </motion.div>
          );
        })}
      </div>

      <AnimatePresence>
        {votes.length === 3 && (
          <motion.div
            key="voting-footer"
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 100 }}
            className="fixed bottom-0 left-0 right-0 bg-white dark:bg-gray-800 shadow-lg p-4 border-t border-gray-200 dark:border-gray-700"
          >
            <div className="max-w-6xl mx-auto">
              <div className="flex flex-col md:flex-row gap-4 items-center">
                <div className="flex-1">
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                    Número de Entrada
                  </label>
                  <div className="flex gap-2">
                    <input
                      type="text"
                      value={entryNumber}
                      onChange={(e) => setEntryNumber(e.target.value)}
                      className="flex-1 px-4 py-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                      placeholder="Ingrese el número de entrada"
                    />
                    <button
                      onClick={() => setIsScanning(true)}
                      className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 dark:bg-indigo-500 dark:hover:bg-indigo-600 flex items-center gap-2"
                    >
                      <QrCodeIcon className="h-5 w-5" />
                      <span className="hidden md:inline">Escanear QR</span>
                    </button>
                  </div>
                </div>
                <button
                  onClick={handleSubmit}
                  disabled={submitting}
                  className={`
                    w-full md:w-auto px-6 py-2 rounded-lg text-white font-medium
                    ${
                      submitting
                        ? "bg-gray-400 cursor-not-allowed"
                        : "bg-indigo-600 hover:bg-indigo-700 dark:bg-indigo-500 dark:hover:bg-indigo-600"
                    }
                    transition-colors duration-200
                  `}
                >
                  {submitting ? "Enviando..." : "Enviar Votos"}
                </button>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence mode="wait">
        {getActiveModal() === "scanning" && (
          <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center p-4 z-50">
            <div className="bg-white dark:bg-gray-800 rounded-lg p-6 w-full max-w-md">
              <Html5QrcodePlugin
                fps={10}
                qrbox={250}
                disableFlip={false}
                qrCodeSuccessCallback={handleQRCodeScan}
                qrCodeErrorCallback={handleScanError}
              />
              <button
                onClick={() => setIsScanning(false)}
                className="mt-4 w-full px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
              >
                Cancelar Escaneo
              </button>
            </div>
          </div>
        )}

        {error && (
          <motion.div
            key="error-modal"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setError("")}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50 cursor-pointer"
          >
            <div className="bg-white dark:bg-gray-800 rounded-lg p-6 max-w-sm w-full">
              <div className="flex flex-col items-center text-center">
                <ExclamationTriangleIcon className="h-12 w-12 text-red-500 mb-4" />
                <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2">
                  Error
                </h3>
                <p className="text-gray-500 dark:text-gray-400">{error}</p>
              </div>
            </div>
          </motion.div>
        )}

        {showSuccessModal && (
          <motion.div
            key="success-modal"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={handleCloseSuccess}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
          >
            <motion.div 
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              className="bg-white dark:bg-gray-800 rounded-lg p-6 max-w-md w-full relative"
              onClick={(e) => e.stopPropagation()}
            >
              <button
                onClick={handleCloseSuccess}
                className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 dark:text-gray-500 dark:hover:text-gray-400"
              >
                <XMarkIcon className="h-6 w-6" />
              </button>

              <div className="flex flex-col items-center text-center">
                <CheckCircleIcon className="h-12 w-12 text-green-500 mb-4" />
                <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-6">
                  ¡Votos Registrados!
                </h3>

                <div className="w-full space-y-4">
                  {submittedVotes.map((vote, index) => (
                    <div
                      key={vote.contestant_id}
                      className={`p-4 rounded-lg bg-${MEDAL_COLORS[vote.vote_value]}-50 dark:bg-${MEDAL_COLORS[vote.vote_value]}-900/30 border border-${MEDAL_COLORS[vote.vote_value]}-200 dark:border-${MEDAL_COLORS[vote.vote_value]}-700`}
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-2">
                          <span className="text-2xl">{MEDAL_EMOJIS[vote.vote_value]}</span>
                          <span className="font-medium text-gray-900 dark:text-white">
                            {vote.contestant_name}
                          </span>
                        </div>
                        <span className="text-lg font-bold text-gray-700 dark:text-gray-300">
                          {vote.vote_value} puntos
                        </span>
                      </div>
                    </div>
                  ))}
                </div>

                <button
                  onClick={handleCloseSuccess}
                  className="mt-8 px-6 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors duration-200"
                >
                  Aceptar
                </button>
              </div>
            </motion.div>
          </motion.div>
        )}

        {submitting && <VoteAnimation />}
      </AnimatePresence>
    </div>
  );
}

export default VotingForm;