import React, { useState } from "react";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import {
  QrCodeIcon,
  UsersIcon,
  CalendarDaysIcon,
  UserGroupIcon,
  DocumentCheckIcon,
  ChartBarIcon,
  ChartBarSquareIcon,
  Bars3Icon,
  XMarkIcon,
  ArrowRightOnRectangleIcon,
  TicketIcon,
  ClipboardDocumentIcon,
} from "@heroicons/react/24/outline";
import EntryValidator from "../components/EntryValidator";
import UserManagement from "../components/admin/UserManagement";
import ContestantManagement from "../components/admin/ContestantManagement";
import ContestantTypeManagement from "../components/admin/ContestantTypeManagement";
import ClassificationDayManagement from "../components/admin/ClassificationDayManagement";
import ContestStatus from "../components/admin/ContestStatus";
import VotingResults from "../components/admin/VotingResults";
import EntriesManagement from "../components/admin/EntriesManagement";
import VoteResults from "../components/admin/VoteResults";
import CacheClearButton from "../components/admin/CacheClearButton";

function UserDashboard() {
  const { auth, logout } = useAuth();
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState("validator");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const menuItems = [
    {
      id: "validator",
      name: "Validar Entradas",
      icon: QrCodeIcon,
      roles: ["admin", "superadmin"],
      component: EntryValidator,
    },
    {
      id: "users",
      name: "Gestión de Usuarios",
      icon: UsersIcon,
      roles: ["superadmin"],
      component: UserManagement,
    },
    {
      id: "contestant-types",
      name: "Tipos de Concursantes",
      icon: DocumentCheckIcon,
      roles: ["superadmin"],
      component: ContestantTypeManagement,
    },
    {
      id: "classification-days",
      name: "Días de Clasificación",
      icon: CalendarDaysIcon,
      roles: ["superadmin"],
      component: ClassificationDayManagement,
    },
    {
      id: "contestants",
      name: "Gestión de Concursantes",
      icon: UserGroupIcon,
      roles: ["superadmin"],
      component: ContestantManagement,
    },
    {
      id: "contest-status",
      name: "Estado del Concurso",
      icon: ChartBarIcon,
      roles: ["superadmin"],
      component: ContestStatus,
    },
    {
      id: "entries",
      name: "Gestión de Entradas",
      icon: TicketIcon,
      roles: ["superadmin"],
      component: EntriesManagement,
    },
    {
      id: "vote-results",
      name: "Votos Registrados",
      icon: ClipboardDocumentIcon,
      roles: ["admin", "superadmin"],
      component: VoteResults,
    },
    {
      id: "voting-results",
      name: "Resultados en directo",
      icon: ChartBarSquareIcon,
      roles: ["superadmin"],
      component: VotingResults,
    },
  ];

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const filteredMenuItems = menuItems.filter((item) =>
    item.roles.includes(auth.role)
  );

  const activeMenuItem = menuItems.find((item) => item.id === activeSection);
  const ActiveComponent = activeMenuItem?.component || (() => null);

  const handleMenuClick = (id) => {
    setActiveSection(id);
    setIsSidebarOpen(false);
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 pt-[60px] lg:pt-0">
      {/* Header móvil */}
      <div className="lg:hidden bg-white dark:bg-gray-800 shadow-lg p-4 flex items-center justify-between fixed top-0 left-0 right-0 z-40">
        <h2 className="text-xl font-bold text-gray-800 dark:text-white">
          {activeMenuItem?.name || "Panel de Control"}
        </h2>
        <button
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          className="text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white"
        >
          {isSidebarOpen ? (
            <XMarkIcon className="h-6 w-6" />
          ) : (
            <Bars3Icon className="h-6 w-6" />
          )}
        </button>
      </div>

      <div className="flex">
        {/* Overlay para móvil */}
        {isSidebarOpen && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 lg:hidden z-20"
            onClick={() => setIsSidebarOpen(false)}
          />
        )}

        {/* Sidebar */}
        <div
          className={`
            fixed inset-y-0 left-0 z-30 w-69 bg-white dark:bg-gray-800 shadow-lg
            transform transition-transform duration-300 ease-in-out lg:translate-x-0 lg:static
            ${
              isSidebarOpen
                ? "translate-x-0"
                : "-translate-x-full lg:translate-x-0"
            }
            flex flex-col h-[calc(100%-60px)] lg:h-screen top-[60px] lg:top-0
          `}
        >
          <div className="flex-1 overflow-y-auto">
            <div className="p-4">
              <h2 className="text-xl font-bold text-gray-800 dark:text-white mb-4 hidden lg:block">
                {activeMenuItem?.name || "Panel de Control"}
              </h2>
              <nav className="space-y-2">
                {filteredMenuItems.map((item) => (
                  <button
                    key={item.id}
                    onClick={() => handleMenuClick(item.id)}
                    className={`w-full flex items-center space-x-2 px-4 py-2 rounded-lg transition-colors duration-200 ${
                      activeSection === item.id
                        ? "bg-indigo-500 text-white"
                        : "text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
                    }`}
                  >
                    <item.icon className="w-5 h-5" />
                    <span>{item.name}</span>
                  </button>
                ))}
              </nav>
            </div>
          </div>

          {/* Footer del Sidebar */}
          <div className="p-4 border-t border-gray-200 dark:border-gray-700">
            {["admin", "superadmin"].includes(auth.role) && (
              <CacheClearButton />
            )}
            <button
              onClick={handleLogout}
              className="w-full flex items-center space-x-2 px-4 py-2 rounded-lg text-red-600 dark:text-red-400 hover:bg-red-50 dark:hover:bg-red-900/20 transition-colors duration-200 mt-2"
            >
              <ArrowRightOnRectangleIcon className="w-5 h-5" />
              <span>Cerrar Sesión</span>
            </button>
          </div>
        </div>

        {/* Main Content */}
        <div className="flex-1 p-4 lg:p-8">
          <div className="max-w-7xl mx-auto">
            <ActiveComponent />
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserDashboard;
