// src/context/ThemeContext.jsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import { getInitialTheme, THEME_DEFAULTS } from '../utils/theme';

const ThemeContext = createContext(null);

export function useTheme() {
  const context = useContext(ThemeContext);
  if (context === null) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
}

export function ThemeProvider({ children, defaultTheme = THEME_DEFAULTS.DEFAULT_THEME }) {
  const [darkMode, setDarkMode] = useState(() => {
    try {
      return getInitialTheme();
    } catch (error) {
      console.warn('Error initializing theme:', error);
      return defaultTheme;
    }
  });

  useEffect(() => {
    try {
      localStorage.setItem(THEME_DEFAULTS.STORAGE_KEY, JSON.stringify(darkMode));
      if (darkMode) {
        document.documentElement.classList.add('dark');
      } else {
        document.documentElement.classList.remove('dark');
      }
    } catch (error) {
      console.warn('Error setting theme:', error);
    }
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode(prev => !prev);
  };

  return (
    <ThemeContext.Provider value={{ darkMode, toggleDarkMode }}>
      {children}
    </ThemeContext.Provider>
  );
}
