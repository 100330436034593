import React from 'react';
import { motion } from 'framer-motion';
import { EnvelopeIcon } from '@heroicons/react/24/outline';

function VoteAnimation() {
  return (
    <div className="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-50 flex items-center justify-center">
      <div className="relative bg-transparent rounded-lg p-6">
        <div className="flex flex-col items-center justify-center">
          <div className="relative h-48 w-48">
            <motion.div
              initial={{ y: 0, scale: 1, rotate: 0 }}
              animate={{
                y: 100,
                scale: 0.8,
                rotate: 15,
                opacity: 0,
              }}
              transition={{
                duration: 2,
                ease: "easeIn",
              }}
              className="absolute top-0 left-1/2 -translate-x-1/2"
            >
              <EnvelopeIcon className="h-12 w-12 text-white" />
            </motion.div>

            <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-32 h-32 text-white"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
                />
              </svg>
            </div>
          </div>

          <p className="mt-4 text-white text-lg font-medium animate-pulse">
            Enviando votos...
          </p>
        </div>
      </div>
    </div>
  );
}

export default VoteAnimation;